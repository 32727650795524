import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IndustryGroupService {
  http: HttpClient;
  baseUrl: string;
  company: Observable<IndustryGroup[]>;

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string) {
    this.http = http;
    this.baseUrl = baseUrl;
  }

  getIndustryGroups(): Observable<IndustryGroup[]> {
    return this.http.get<IndustryGroup[]>(this.baseUrl + 'api/industrygroup');
  }

  getIndustryGroup(id): Observable<IndustryGroup[]> {
    return this.http.get<IndustryGroup[]>(this.baseUrl + 'api/industrygroup/' + id);
  }

  saveIndustryGroup(body: IndustryGroup, id: number): Observable<any> {
    return this.http.put(this.baseUrl + 'api/industrygroup/saveindustrygroup/' + id, body);
  }

  deleteIndustryGroup(id) {
    this.http.delete(this.baseUrl + 'api/industrygroup/deleteindustrygroup/' + Number(id)).subscribe(
      result => console.log(result),
      err => console.error(err)
    );;
  }



  getKpiIndustryGroups(): Observable<KpiIndustryGroup[]> {
    return this.http.get<KpiIndustryGroup[]>(this.baseUrl + 'api/kpiindustrygroup/all');
  }

  getKpiIndustryGroupsByIndustry(id: number): Observable<KpiIndustryGroup[]> {
    return this.http.get<KpiIndustryGroup[]>(this.baseUrl + 'api/kpiindustrygroup/industrygroup:' + id.toString());
  }

  getKpiIndustryGroupsByKPI(id: number): Observable<KpiIndustryGroup[]> {
    return this.http.get<KpiIndustryGroup[]>(this.baseUrl + 'api/kpiindustrygroup/kpi:' + id.toString());
  }

  getKpiIndustryGroup(id): Observable<KpiIndustryGroup[]> {
    return this.http.get<KpiIndustryGroup[]>(this.baseUrl + 'api/kpiindustrygroup/' + id.toString());
  }

  saveKpiIndustryGroup(body: KpiIndustryGroup, id: number): Observable<any> {
    return this.http.put(this.baseUrl + 'api/kpiindustrygroup/savekpiindustrygroup/' + id.toString(), body);
  }

  deleteKpiIndustryGroup(id) {
    return this.http.delete(this.baseUrl + 'api/kpiindustrygroup/deletekpiindustrygroup/' + id.toString());
  }
}


export interface IndustryGroup {
  industryGroupId: number;
  name: string;
  description: string;
}

export interface KpiIndustryGroup {
  kpiIndustryGroupId: number;
  kpiId: number;
  kpiName: string;
  industryGroupId: number;
  industryGroupName: string;
  ratioValue: number;
}
